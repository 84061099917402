import React, { useEffect } from "react";
import image404 from "../assets/404.jpg";
import { Image, Button } from "semantic-ui-react";
import { Container } from "reactstrap";
import AuthNavMenu from "../components/layout/navBars/AuthNavMenu";

const PageNotfound = () => {
  useEffect(() => {
    document.title += ' - Ups'
  })
  return (
    <div>
      <AuthNavMenu />
      <Container className='error-page'>
        <Image src={image404} size="big" verticalAlign="middle" />
        <br />
        <Button
          content="Back"
          icon="left arrow"
          onClick={() => window.history.back()}
          primary
          labelPosition="left"
        />
      </Container>
    </div>
  );
};

export default PageNotfound;
