import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import LoadingComponent from "../../components/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { Grid, Header, Segment, Card, Button } from "semantic-ui-react";
import Profile from "./Profile";

const ManageOrders = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    gettingData,
    OpenModal,
    ordersSaved,
    ordersSent,
    GetOrders,
    OpenDelModal,
  } = rootStore.profileStore;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title += " - Manage Orders";
    GetOrders().then(() => setLoading(false));
  }, [GetOrders]);

  if (gettingData) return <LoadingComponent content={"Loading data..."} />;
  return (
    <Profile>
      <Grid centered>
        <Grid.Column width={16} textAlign="center">
          <Header as="h2" attached="top">
            Manage your Orders
          </Header>
          <Segment style={{ borderWidth: 0 }} clearing loading={loading}>
            <Grid columns={2} style={{ borderWidth: 0 }}>
              <Grid.Row style={{ borderWidth: 0 }}>
                <Grid.Column width={8}>
                  <Header as="h2" attached="top" textAlign="center">
                    Saved
                  </Header>
                  <Segment
                    clearing
                    style={{ height: 560, overflowY: "scroll" }}
                  >
                    <Card.Group>
                      {ordersSaved ? (
                        ordersSaved.map((e, i) => (
                          <Card key={i} style={{ width: "100%" }}>
                            <Card.Content>
                              <Card.Header>
                                Saved on: {e.orderDate.split("T")[0]}{" "}
                              </Card.Header>
                              <Card.Description
                                style={{ textAlign: "justify" }}
                              >
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    {" "}
                                    <strong>Outlet Name: </strong>{" "}
                                    {e.outlet.name}
                                  </li>
                                  {e.products.length !== 0 ? (
                                    <li>
                                      <strong>Products Ordered: </strong>
                                      {e.products.length}
                                    </li>
                                  ) : null}

                                  <li>
                                    <strong>Saved by: </strong>{" "}
                                    {e.orderSender.username}
                                  </li>

                                  {e.deliveryPointReference ? (
                                    <li>
                                      <strong>Delivery Point Ref: </strong>
                                      {e.deliveryPointReference}
                                    </li>
                                  ) : null}
                                </ul>
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                              <div className="ui two buttons">
                                <Button
                                  positive
                                  onClick={() => OpenModal(e, e.status)}
                                  labelPosition="right"
                                  icon="eye"
                                  content="View Order"
                                />
                                <Button
                                  color="red"
                                  onClick={() => OpenDelModal(e.orderId)}
                                  labelPosition="right"
                                  icon="trash"
                                  content="Delete Order"
                                />
                              </div>
                            </Card.Content>
                          </Card>
                        ))
                      ) : (
                        <h2>Nothing to show</h2>
                      )}
                    </Card.Group>
                  </Segment>
                </Grid.Column>

                <Grid.Column width={8}>
                  <Header as="h2" textAlign="center" attached="top">
                    Sent
                  </Header>
                  <Segment
                    clearing
                    style={{ height: 560, overflowY: "scroll" }}
                  >
                    <Card.Group>
                      {ordersSent ? (
                        ordersSent.map((e, i) => (
                          <Card key={i} style={{ width: "100%" }}>
                            <Card.Content>
                              <Card.Header>
                                Sent on: {e.orderDate.split("T")[0]}{" "}
                              </Card.Header>
                              <Card.Description
                                style={{ textAlign: "justify" }}
                              >
                                <ul style={{ listStyleType: "none" }}>
                                  <li>
                                    {" "}
                                    <strong>Outlet Name: </strong>{" "}
                                    {e.outlet.name}
                                  </li>
                                  <li>
                                    <strong>Products Ordered: </strong>{" "}
                                    {e.products.length}
                                  </li>
                                  <li>
                                    <strong>Placed by: </strong>{" "}
                                    {e.orderSender.username}
                                  </li>
                                  <li>
                                    <strong>My Reference: </strong>{" "}
                                    {e.customerReference}
                                  </li>
                                </ul>
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                              <div className="ui two buttons">
                                <Button
                                  fluid
                                  onClick={() => OpenModal(e, e.status)}
                                  positive
                                  labelPosition="right"
                                  icon="eye"
                                  content="View Order"
                                />
                              </div>
                            </Card.Content>
                          </Card>
                        ))
                      ) : (
                        <h2>Nothing yo show</h2>
                      )}
                    </Card.Group>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </Profile>
  );
};

export default observer(ManageOrders);
