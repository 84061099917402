import React from "react";
import { Message } from "semantic-ui-react";

const WarningMessage = () => {
  return (
    <div>
      <Message warning>
        <p>
          <strong style={{ fontSize: 17 }}>Important Note!</strong> Please be
          aware that <strong>Last Known Stock</strong> may have <u>changed</u>{" "}
          at the time of placing this order
        </p>
      </Message>
    </div>
  );
};
export default WarningMessage;
