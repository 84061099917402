import React, { useContext } from "react";
import TextInput from "../components/layout/form/TextInput";
import { FORM_ERROR } from "final-form";
import { Grid, Segment, Form, Button, Message } from "semantic-ui-react";
import { Container, Label } from "reactstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { IResetPassword } from "../models/resetPasswordModel";
import { RootStoreContext } from "../stores/rootStore";
import { combineValidators, isRequired, matchesField } from "revalidate";
import ErrorMessage from "../components/layout/ErrorMessage";
import AuthNavMenu from "../components/layout/navBars/AuthNavMenu";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  password: isRequired("password"),
  password_confirmation: matchesField(
    "password",
    "password_confirmation"
  )({ message: "Passwords do not match" }),
});

const cleanToken = (block_1: string, block_2: string, block_3: string) => {
  let localTokenString = block_1 + block_2 + block_3;
  localTokenString = localTokenString.replace(/\PLUS/g, "+");
  localTokenString = localTokenString.replace(/\SLASH/g, "/");
  return localTokenString;
};

function ResetPassword(props: any) {
  const { match } = props;
  const rootStore = useContext(RootStoreContext);
  const { ResetPassword } = rootStore.userStore;
  let { passToken_block1 } = match.params;
  let { passToken_block2 } = match.params;
  let { passToken_block3 } = match.params;
  let { email } = match.params;
  let { username } = match.params;

  return (
    <div>
      <AuthNavMenu />
      <Container>
        <h2 className="lead">Please set your password!</h2>
        <br />
        <Grid centered>
          <Grid.Column width={6}>
            <Segment clearing>
              <FinalForm
                onSubmit={(values: IResetPassword) =>
                  ResetPassword(values).catch((error) => ({
                    [FORM_ERROR]: error,
                  }))
                } 
                validate={validate}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  invalid,
                  pristine,
                  dirtySinceLastSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} error>
                    <Field
                      name="email"
                      type="hidden"
                      value={email}
                      initialValue={email}
                      component={"input"}
                    />

                    <Label>
                      <strong>Username</strong>
                    </Label>
                    <Field
                      name="username"
                      type="text"
                      readOnly={true}
                      value={username}
                      initialValue={username}
                      component={"input"}
                    />
                    <Field
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      component={TextInput}
                    />
                    <Field
                      label="Repeat Password"
                      name="password_confirmation"
                      type="password"
                      placeholder="Repeat Password"
                      component={TextInput}
                    />
                    <Field
                      name="token"
                      type="hidden"
                      initialValue={cleanToken(
                        passToken_block1,
                        passToken_block2,
                        passToken_block3
                      )}
                      defaultValue={cleanToken(
                        passToken_block1,
                        passToken_block2,
                        passToken_block3

                      )}
                      component={TextInput}
                    />
                    {submitError && !dirtySinceLastSubmit && (
                     <ErrorMessage error={submitError} />
                    )}
                    <br></br>
                    <Button
                      fluid
                      icon="key"
                      loading={submitting}
                       disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                      primary
                      content="Submit"
                    />
                  </Form>

)}
              />

            </Segment>
          </Grid.Column>
        <Grid.Column>
          </Grid.Column> 
        <Grid.Column>
        <Message compact warning style={{width: 193}}>
            <Message.Header>Password Criteria: </Message.Header>
            <Message.List>
              <Message.Item>Passwords must be at least 6 characters.</Message.Item>
              <Message.Item>Passwords must have at least one non alphanumeric character.</Message.Item>
              <Message.Item>Passwords must have at least one digit ('0'-'9').</Message.Item>
              <Message.Item>  Passwords must have at least one uppercase ('A'-'Z').</Message.Item>

            </Message.List>
          </Message>
        </Grid.Column>
        
        </Grid>
        
      </Container>
    </div>
  );
}
export default observer(ResetPassword);