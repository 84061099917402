import { action, runInAction, observable } from "mobx";
import agent from "../API/agent";
import { RootStore } from "./rootStore";
import { IDataOrderProfile } from "../models/order";
import { IUser } from "../models/user";
import { toast } from "react-toastify";
import { history } from "..";

export default class ProfileStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable profileData: IDataOrderProfile[] = [];
  @observable ordersSent: IDataOrderProfile[] = [];
  @observable ordersSaved: IDataOrderProfile[] = [];
  @observable orderStatusFlag: number;

  // modal data/ variables
  // order details
  @observable modalOpen = false;
  @observable orderSelected: IDataOrderProfile;

  // delete confirmation
  @observable delModalOpen = false;
  @observable orderSelectedID: string;

  // order details modal
  @action closeModal = async () => {
    runInAction(() => {
      this.modalOpen = false;
    });
  };
  @action OpenModal = async (data: IDataOrderProfile, status: number) => {
    runInAction(() => {
      this.orderStatusFlag = status;
      this.orderSelected = data;
      this.modalOpen = true;
    });
  };
  // end order details modal
  // delete saved order modal
  @action closeDelModal = async () => {
    runInAction(() => {
      this.delModalOpen = false;
    });
  };
  @action OpenDelModal = async (orderId: string) => {
    try {
      runInAction(() => {
        this.delModalOpen = true;
        this.orderSelectedID = orderId;
      });
    } catch (error) {
      console.log("[OPEN DEL MODAL ]", error);
    }
  };
  @action removeConfirmation = async () => {
    try {
      const orderRemoved = await agent.profile.delete(this.orderSelectedID);

      if (orderRemoved > 0) {
        runInAction(() => {
          this.GetOrders();

          this.delModalOpen = false;
          toast.success("Order Removed Successfully!");
          this.modalOpen = false;
        });
      }
    } catch (error) {
      console.log("[REMOVE CONFIRMATION ERROR]:", error);
    }
  };
  // end delete saved order modal
  @action GetOrders = async () => {
    try {
      var user: IUser = this.rootStore.userStore.user!;
      const profileData = await agent.profile.list(user.company.key);

      runInAction(() => {
        this.ordersSaved = profileData.filter(s => s.status === false);
        this.ordersSent = profileData.filter(s => s.status === true);
      });
    } catch (err) {
      console.log("[GET PROFILE DATA ERROR]: ", err);
    }
  };
  @action handlePlacingSavedOrder = async (orderId: string, placeOrderFlag: true) => {
    runInAction(() => {
      history.push(`/`);
      this.modalOpen = false;
      this.rootStore.orderStore.HandlePlacingSavedOrder(orderId, placeOrderFlag);
    });
  };  
  @action handleRepeatOrder = async (orderId: string, repeatOrderFlag: boolean) => {
    runInAction(() => {
      history.push(`/`);
      this.rootStore.orderStore.outletOpenFlag = true
      this.rootStore.orderStore.productOpenFlag = false;
      this.modalOpen = false;
      this.rootStore.orderStore.handleRepeatOrder(orderId, repeatOrderFlag);
    });
  };
}
