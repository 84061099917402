import React, { useContext } from "react";
import { Image, Grid, Segment, Header } from "semantic-ui-react";
import AdminNavBar from "../../components/layout/navBars/AdminNavBar";
import Footer from "../../components/layout/Footer";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import AdminLogo from "../../assets/admin.png";
const Profile = (props) => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.userStore;
  const { order } = rootStore.orderStore;

  return (
    <div>
      <AdminNavBar />
      <Grid columns="2" centered>
        <Grid.Column width="3" className="profile-left-column">
          <div>
            <br />
            <Image
              active={"true"}
              key={1}
              style={{
                padding: 10,
                borderStyle: "solid",
                borderWidth: 0.9,
                borderRadius: 3,
                borderColor: "silver",
              }}
              src={
                isLoggedIn && user.company ? user.company.photo.url : AdminLogo
              }
            />
          </div>
          <hr />
          {order ? (
            <Segment className="ui.segment.place.order">
              <Header as="h4" attached="top">
                Delivery Address
              </Header>
              {order.M_outlet_name}
              <br />
              {order.N_delivery_address_1}
              <br />
              {order.O_delivery_address_2}
              <br />
              {order.Q_delivery_address_3}
              <br />
              {order.S_delivery_town}
              <br />
              {order.T_delivery_address_5}
              <br />
            </Segment>
          ) : (
            ""
          )}
        </Grid.Column>

        <Grid.Column width="13">
          <br />
          <br />
          {props.children}
        </Grid.Column>
      </Grid>
      <Footer />
    </div>
  );
};
export default observer(Profile);
