import React from "react";
import { observer } from "mobx-react-lite";

function Footer() {
 
  
    return (
    <div>
        <br/>
        <br/>
        <br/>
        <br/>
      <footer className="footer align-middle pt-2">
 

        <div className="container text-center">
            <div className="navbar navbar-expand navbar-light py-0">
                <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="http://www.masterlink.ie/terms_conditions.pdf" rel="noopener noreferrer" target="_blank">Terms &amp; Conditions</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="http://www.masterlink.ie/" asp-action="Contact">Contact Us</a>
                    </li>
                </ul>
            </div>
            <p className="text-muted">
                Copyright &copy;{new Date().getFullYear()}
                <a href="http://www.masterlink.ie/"> Masterlink</a>
            </p>
        </div>
    </footer>
    </div>
  );
}
export default observer(Footer)