import React, { useContext } from "react";
import { RootStoreContext } from "../stores/rootStore";
import { Container } from "reactstrap";
import ErrorMessage from "../components/layout/ErrorMessage";
import { Form as FinalForm, Field } from "react-final-form";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import TextInput from "../components/layout/form/TextInput";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import AuthNavMenu from "../components/layout/navBars/AuthNavMenu";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  username: isRequired("username")
});

function ForgotPassword() {
  const rootStore = useContext(RootStoreContext);
  const {  RequestPassword } = rootStore.userStore;

  return (
    <div>
      <div>
        <AuthNavMenu />
        <Container>
          <p className="h3">Forgot password</p>
          <br />

          <Grid centered>
            <Grid.Column width={6}>
              <Segment clearing>
                <FinalForm
                  onSubmit={(user: any) =>
                    RequestPassword(user).catch(error => ({
                      [FORM_ERROR]: error
                    }))
                  }
                  validate={validate}
                  render={({
                    handleSubmit,
                    submitting,
                    submitError,
                    invalid,
                    pristine,
                    dirtySinceLastSubmit
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Field
                        label="Username"
                        name="username"
                        placeholder="Username"
                        component={TextInput}
                      />
                      {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage
                          error={submitError}
                          text={"Error resetting password"}
                        />
                      )}
                      <br></br>
                      <Button
                        fluid
                        icon="key"
                        loading={submitting}
                        disabled={
                          (invalid && !dirtySinceLastSubmit) || pristine
                        }
                        primary
                        content="Reset password"
                      />
                    </Form>
                  )}
                />
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default observer(ForgotPassword);
