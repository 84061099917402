import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../../stores/rootStore";
import LoadingComponent from "../../layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import {
  Button,
  Modal,
  List,
  Table,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";

const OrderDetailsModal = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    gettingData,
    modalOpen,
    orderSelected,
    closeModal,
    orderStatusFlag,
    OpenDelModal,
    GetOrders,
    handleRepeatOrder,
    handlePlacingSavedOrder,
  } = rootStore.profileStore;

  useEffect(() => {
    return () => {
      GetOrders();
    };
  }, [GetOrders]);

  if (modalOpen && gettingData)
    return <LoadingComponent content={"Loading data..."} />;
  return (
    // open={delModalOpen} onClose={closeDelModal} closeIcon
    <Modal
      onClose={closeModal}
      open={modalOpen}
      style={{
        height: 900,
        width: 900,
        position: "fixed",
        top: 450,
        left: 0,
        bottom: 0,
        right: 0,
        margin: "auto",
      }}
    >
      <Modal.Header>Order Details </Modal.Header>

      {orderSelected ? (
        <div
          style={{
            overflowY: "scroll",
            height: 750,
            width: 900,
            position: "absolute",
            paddingLeft: 15,
            paddingTop: 15,
            paddingRight: 15,
          }}
        >
          <Modal.Content>
            <h3>Posted By: </h3>
            <List>
              <List.Item>
                <List.Icon name="calendar times outline" />
                <List.Content>
                  {" "}
                  {orderSelected.orderDate.split("T")[0]}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="user" />
                <List.Content>
                  {" "}
                  {orderSelected.orderSender.username}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="mail" />
                <List.Content>
                  <p>{orderSelected.orderSender.emailAddress}</p>
                </List.Content>
              </List.Item>
            </List>

            <hr />

            <Grid centered columns={2}>
              <Grid.Column width="8">
                <Header as="h3" content="Customer" dividing />
                <Table selectable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Customer Name</Table.Cell>
                      <Table.Cell>{orderSelected.outlet.name}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Order Type Code</Table.Cell>

                      <Table.Cell>{orderSelected.ordertype}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>My Reference</Table.Cell>
                      <Table.Cell>{orderSelected.customerReference}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Delivery Point Reference</Table.Cell>
                      <Table.Cell>
                        {orderSelected.deliveryPointReference}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Special Instructions</Table.Cell>
                      <Table.Cell>
                        {orderSelected.specialInstruction}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width="8">
                <Header as="h3" content="Delivery Address" dividing />

                <Segment>
                  {orderSelected.outlet.address.addressLine1}
                  <br />
                  {orderSelected.outlet.address.addressLine2}
                  <br />
                  {orderSelected.outlet.address.addressLine3}
                  <br />
                  {orderSelected.outlet.address.addressLine4}
                  <br />
                  {orderSelected.outlet.address.addressLine5}
                  <br />
                </Segment>
              </Grid.Column>
            </Grid>

            <br />

            <h3>Products</h3>
            {orderSelected.products.length > 0 ? (
              <Table selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Code</Table.Cell>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>Case Units</Table.Cell>
                    <Table.Cell>Singles Units</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {orderSelected.products.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.productCode}</Table.Cell>
                      <Table.Cell>{e.name}</Table.Cell>
                      <Table.Cell>{e.caseUnits}</Table.Cell>
                      <Table.Cell>{e.singleUnits}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <h3>No products to show!</h3>
            )}
            <br />
            <br />
          </Modal.Content>
        </div>
      ) : (
        false
      )}

      <div className="modalFooter">
        <hr />
        <Modal.Actions>
          <div className="ui tree buttons">
            {" "}
            <Button
              style={{ marginRight: 2 }}
              icon="delete"
              labelPosition="right"
              onClick={closeModal}
              color="yellow"
              content="Close"
            />
            {orderStatusFlag === false ? (
              <div>
                <Button
                  style={{ marginRight: 2 }}
                  positive
                  onClick={() =>
                    handlePlacingSavedOrder(orderSelected.orderId, true)
                  }
                  labelPosition="right"
                  icon="archive"
                  content="Place Order"
                />
                <Button
                  style={{ marginRight: 2 }}
                  onClick={() => OpenDelModal(orderSelected.orderId)}
                  color="red"
                  // onClick={closeModal}
                  labelPosition="right"
                  icon="trash"
                  content="Delete Order"
                />
              </div>
            ) : (
              <Button
                style={{ marginRight: 2 }}
                positive
                onClick={() => handleRepeatOrder(orderSelected.orderId, true)}
                labelPosition="right"
                icon="archive"
                content="Repeat Order"
              />
            )}
          </div>
        </Modal.Actions>
      </div>
    </Modal>
  );
};

export default observer(OrderDetailsModal);
