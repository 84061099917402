import React from 'react';
import { AxiosResponse } from 'axios';
import { Message } from 'semantic-ui-react';

interface IProps {
  error: AxiosResponse;
  text?: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text }) => {

 
  return (
    <Message error>
      {console.log(error)}
      <Message.Header>{error.statusText}</Message.Header>
      {error.data && Object.keys(error.data).length > 0 && (
        <Message.List>
          {Object.values(error.data)
            .flat()
            .map((err: any, i) => (
              <Message.Item key={i}>{err["Description"]}</Message.Item>
            ))}
        </Message.List>
      )}
      {text && <Message.Content content={text} />}
    </Message>
  );
};

export default ErrorMessage;