import { action, runInAction, observable } from "mobx";
import agent from "../API/agent";
import { RootStore } from "./rootStore";
import { ICompany } from "../models/company";
import { ICompanySubmit } from "../models/ICompanySubmit";
import { toast } from "react-toastify";
import { history } from "..";

export default class CompanyStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable companies: ICompany[] = [];
  @observable companyLogo: Blob[] = [];

  @observable submittingData = false;

  @action SetCompanyPhoto = async (photo: any) => {
    runInAction(() => {
      this.companyLogo = photo;
    });
  };

  @action GetCompanies = async () => {
    try {
      const localComp = await agent.company.list();
      runInAction(() => {
        this.companies = localComp;
      });
    } catch (err) {
      console.log("[GET COMPANIES ERROR]: ", err);
    }
  };
  @action addNewCompanyData = async (values: ICompanySubmit) => {
    try {
      runInAction(() => {
        values.photo = this.companyLogo[0];
      });

      const companyData = await agent.company.addCompanyData(values);

      runInAction(() => {
        if (companyData) {
          history.push("/dashboard");
          toast.success("Company Details Added Successfully");
        }
      });
    } catch (error) {
      throw error;
      
    }
  };
}
