import React from 'react'
import { Message } from 'semantic-ui-react'

interface IProps{
  text: string
}
  const UnitsErrorMessage: React.FC<IProps> = ({text}) => {
    return (
      <Message error>
        {text}
     
    </Message>
    )
}

export default UnitsErrorMessage