import axios, { AxiosResponse } from "axios";
import { IUser, IUserFormValues, IRegisteredUser, IRole } from "../models/user";
import { IOrder, IDataOrderProfile } from "../models/order";
import { toast } from "react-toastify";
import { IRegisterModel } from "../models/registerModel";
import { IResetPassword } from "../models/resetPasswordModel";
import { ICompany } from "../models/company";
import { ICompanyData } from "../models/ICompanyData";
import { ICompanySubmit } from "../models/ICompanySubmit";
import { IOutletLists } from "../models/Outlet";
import { IProductLists } from "../models/Product";
import { history } from "..";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem("jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, error => {
  const { status, config } = error.response;

  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure the API is running");
  }
  if (status === 401) {
    localStorage.removeItem("jwt");
    history.push("/");
    toast.info("Your session has expired, please login again! ");
  }
  if (status === 404) {
    toast.error("Resource Not Found - check the terminal for more info");
  }
  if (status === 400 && config.method === "get") {
    toast.error("Server Error - check the terminal for more info");
  }
  if (status === 403 && config.method === "get") {
    toast.error("Authorization Error - You are not authorized to access this resource!");
  }
  if (status === 500) {
    toast.error("Server Error - check the terminal for more info");
  }


  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;
const request = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, values: ICompanySubmit) => {
    var formData = new FormData();
    formData.append("file", values.photo);
    formData.append("Name", values.name);
    formData.append("Code", values.code);
    formData.append("FtpName",values.ftpName)
    formData.append("FtpPassword",values.ftpPassword)
    formData.append("warehousecode", values.warehousecode);
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(responseBody);
  }
};
const Order = {

  findOrder: (orderId: string) => request.get(`/find-order/${orderId}` ),
  create: (order: IOrder) => request.post("/Order/place-order", order),
  save: (order: IOrder) => request.post("/Order/save-order", order),
  searchOutlets: (query: string,saleCode: string ,warehouseCode: number, delMethod: string): Promise<IOutletLists> =>
    request.get(`/search-outlet/${query}/${saleCode}/${warehouseCode}/${delMethod}`),


  searchProducts: (
    warehouseCode: number,
    query: string
  ): Promise<IProductLists> =>
    request.get(`/search-product/${warehouseCode}/${query}`)
};

const User = {
  current: (): Promise<IUser> => request.get("/user"),
  register: (values: IRegisterModel): Promise<IRegisteredUser> =>
    request.post("/register", values),
  resetPassword: (values: IResetPassword): Promise<AxiosResponse> =>
    request.post("/user-reset-password", values),
  login: (user: IUserFormValues): Promise<IUser> =>
    request.post("account/login", user),
  forgetPassword: (username: string): Promise<AxiosResponse> =>
    request.get(`/request-password-email/${username}`),
};

const company = {
  list: (): Promise<ICompany[]> => request.get("/get-companies"),
  addCompanyData: (values: ICompanySubmit): Promise<ICompanyData> =>
    request.postForm("/add-company-details", values)
};
const profile = {
  list: (companyKey: string): Promise<IDataOrderProfile[]> =>
    request.get(`/get-orders/${companyKey}`), 
     
  delete: (orderId: string) => request.del(`/delete-order/${orderId}`)
};


const roles = {
  list: (): Promise<IRole[]> => request.get("/get-roles"),
};
export default {
  User,
  profile,
  Order,
  roles,
  company
};
