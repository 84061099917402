import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { usePalette } from "react-palette";
import logo from "../../../assets/logo.png";
import { RootStoreContext } from "../../../stores/rootStore";
import { Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { history } from "../../..";

function AdminNavBar() {
  const rootStore = useContext(RootStoreContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOrderOpen, setDropdownOrderOpen] = useState(false);
  const { logout, user, isLoggedIn } = rootStore.userStore;
  const [path, setPath] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const orderToggle = () => setDropdownOrderOpen((prevState) => !prevState);
  const { data } = usePalette(user.company.photo.url);
  useEffect(() => {
    if (isLoggedIn) {
          
    if (user !== null && user.role === "Admin") setPath("/dashboard");
    else setPath("/customer-profile");
    }
  }, [user, setPath, isLoggedIn]);

  return (
    <div>
      <Navbar
     style={{ backgroundColor: "#F8F9FA", height: 77 }}
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        light
      >
        <Container style={{ backgroundColor: "#F8F9FA" }}>
          <NavbarBrand tag={Link} to={isLoggedIn ? path : "/"}>
          <img
              src={logo}
              className="float-right"
              style={{ width: "200px", height: "65px" }}
              alt="OrderIT Logo"
            />
          </NavbarBrand>
          <ul className="navbar-nav float-left">
            <NavItem>
              <Dropdown
                isOpen={dropdownOrderOpen}
                toggle={orderToggle}
                className="nav-link"
              >
                <DropdownToggle
                  style={{
                    backgroundColor: "#F8F9FA",
                    color: "#4a4a4b",
                    borderWidth: 0,
                  }}
                  caret
                >
                  Order
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => history.push(`/customer-profile`)}
                  >
                    Place Order
                  </DropdownItem>
                  <DropdownItem onClick={() => history.push(`/my-orders`)}>
                    My Orders
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>

            <NavItem>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="nav-link"
              >
                <DropdownToggle
                  style={{
                    backgroundColor: "#F8F9FA",
                    color: "#4a4a4b",
                    borderWidth: 0,
                  }}
                  caret
                >
                  <Icon name="user circle" />
                  {user ? user.username : ""}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={logout}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </ul>
        </Container>
        {isLoggedIn ? (
          <div style={{ textAlign: "right" }}>
            <a
              style={{ textDecoration: "none" }}
              href="https://order.masterlink.ie/manual/manual_1_1.pdf"
              target="_black"
            >
              <Icon
             
                size={"big"}
                name="book"
              />{" "}
              Manual
            </a>
          </div>
        ) : null}
      </Navbar>
      <div
        style={{
          height: 5,
          backgroundColor: data.vibrant,
          paddingBottom: 5,
          marginTop: -15,
        }}
      />
    </div>
  );
}

export default observer(AdminNavBar);
