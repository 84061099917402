import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthenticationRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('jwt') !== null
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  )

export default AuthenticationRoute;
