
import UserStore from './userStore';
import CompanyStore from './companyStore'
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import OrderStore from './orderStore';
import ProfileStore from './profileStore';
import ModalStore from './modalStore';


configure({enforceActions: 'always'});

export class RootStore {
    userStore: UserStore;
    commonStore: CommonStore;
    companyStore: CompanyStore;
    orderStore: OrderStore;
    profileStore: ProfileStore;
    modalStore: ModalStore;
    
    constructor() {
        this.modalStore = new ModalStore(this);
        this.orderStore = new OrderStore(this)
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this);
        this.companyStore = new CompanyStore(this);
        this.profileStore = new ProfileStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());