import { FORM_ERROR } from "final-form";
import React, { useContext } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ICompanySubmit } from "../../models/ICompanySubmit";
import SelectInput from "../../components/layout/form/SelectInput";
import TextInput from "../../components/layout/form/TextInput";
import LoadingComponent from "../../components/layout/LoadingComponent";
import { RootStoreContext } from "../../stores/rootStore";
import UploadPhoto from "../../components/admin/photo/uploadPhoto";
import { observer } from "mobx-react-lite";
import RegisterErrorMessage from "../../components/layout/RegisterErrorMessage";

const validate = combineValidators({
  name: isRequired("Company Name"),
  code: isRequired("Company Code"),
  warehousecode: isRequired("Warehouse Code"),
  ftpName: isRequired("FTP Name"),
  ftpPassword: isRequired("FTP Password")


});

const AddCompany = () => {
  const rootStore = useContext(RootStoreContext);
  const { addNewCompanyData, submittingData } = rootStore.companyStore;

  if (submittingData)
    return <LoadingComponent content="Submitting details ..." />;

  return (
    <Grid centered>
      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Register a new Company
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompanySubmit) =>
              addNewCompanyData(values).catch(error => ({
                [FORM_ERROR]: error
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,

              dirtySinceLastSubmit
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    name="name"
                    placeholder="Company Name"
                    component={TextInput}
                  />
                  <Field
                    label="Tenant Code"
                    name="code"
                    placeholder="Tenant Code"
                    component={TextInput}
                    maxLength={4}
                  />
                  <Field
                    label="Warehouse Code"
                    name="warehousecode"
                    placeholder="Warehouse Code"
                    component={TextInput}
                    maxLength={4}
                  />
                </Form.Group>
                <Form.Group widths="6">
                  <Field
                    label="FTP Name"
                    name="ftpName"
                    placeholder="FTP Name"
                    component={TextInput}
                  />
                  <Field
                    label="FTP Password"
                    name="ftpPassword"
                    placeholder="FTP Password"
                    component={TextInput}
              
                  />
             
                </Form.Group>
                <UploadPhoto />

                <br></br>
                <Form.Group widths="equal" style={{ float: "right" }}>
                  {submitError && !dirtySinceLastSubmit && (
                    <RegisterErrorMessage error={submitError} />
                  )}

                  <Button
                    icon="plus"
                    positive
                    loading={submitting}
                    content="Submit"
                  />
                </Form.Group>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>

      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Update Company Details
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompanySubmit) =>
              addNewCompanyData(values).catch(error => ({
                [FORM_ERROR]: error
              }))
            }
            validate={validate}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    options={[]}
                    name="name"
                    placeholder="Company Name"
                    component={SelectInput}
                  />
                </Form.Group>

                <Form.Group style={{ float: "right" }}>
                  <Button
                    icon="remove"
                    labelPosition="right"
                    primary
                    content="Update"
                  />
                </Form.Group>

                <br></br>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>

      <Grid.Column width={15}>
        <Header as="h2" attached="top">
          Remove Company
        </Header>
        <Segment clearing>
          <FinalForm
            onSubmit={(values: ICompanySubmit) =>
              addNewCompanyData(values).catch(error => ({
                [FORM_ERROR]: error
              }))
            }
            validate={validate}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Field
                    label="Company Name"
                    options={[]}
                    name="name"
                    placeholder="Company Name"
                    component={SelectInput}
                  />{" "}
                </Form.Group>

                <Form.Group style={{ float: "right" }}>
                  <Button
                    icon="remove"
                    labelPosition="right"
                    color="red"
                    content="Remove"
                  />
                </Form.Group>

                <br></br>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
export default observer(AddCompany);
