import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { history } from "..";
import { IOrder, IDataOrderProfile } from "../models/order";
import { IOutlet, IOutletSearchResult } from "../models/Outlet";
import { IOrderOutlet } from "../models/order";
import {
  IProduct,
  IProductSearchResult,
  IProductFinalList,
} from "../models/Product";
import { RootStore } from "./rootStore";
import agent from "../API/agent";
import { IUser } from "../models/user";
import { Cookies } from "../classes/Cookies";

export default class OrderStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable order: IOrder | null;
  @observable orderArr: IOrder[] = [];
  // outlet
  @observable outletsList: IOutlet[] = [];
  @observable outletListSearchResult: IOutletSearchResult[] = [];
  @observable initialState: IOutletSearchResult | null;
  @observable orderType: string = "DP";
  @observable defaultDeliveryMethod: string = "DEFAULT";
  @observable delMethodChanged: boolean = false;

  // product
  @observable productsList: IProduct[] = [];
  @observable productsListSearchResult: IProductSearchResult[] = [];
  @observable orderTableList: IProductSearchResult[] = [];
  @observable TC_checked: boolean;
  @observable boundOrderFlag: boolean;
  @observable selectedOutlet: IOutlet;
  @observable orderOutlet: IOrderOutlet;
  @observable orderFlag_2: boolean = false;
  // clear form
  @observable submittingData: boolean = false;
  // data reserved from the HTTP request to get order by ID
  @observable orderToRepeat: IDataOrderProfile;
  @observable specialInstructions: string = "";
  @observable myReference: string = "";
  @observable delPointReference: string = "";
  @observable singleUnitsError: Boolean = false;
  @observable caseUnitsError: Boolean = false;
  @observable outletOpenFlag: boolean = true;
  @observable productOpenFlag: boolean = false;
  @observable isRepeatOrder: boolean = false;
  @observable isPlacingOrder: boolean = false;

  @action setDeliveryMethod = async (value: string) => {
    this.defaultDeliveryMethod = value;
    this.delMethodChanged = true;
  };
  @action resetDelMethodChange = async () => {
    this.delMethodChanged = false;
  };
  @action openCloseOutletProductWindow = (flagName: string) => {
    switch (flagName) {
      case "outletFlag":
        runInAction(() => {
          this.outletOpenFlag = !this.outletOpenFlag;
          this.productOpenFlag = !this.productOpenFlag;
        });
        break;
      case "productFlag":
        runInAction(() => {
          this.outletOpenFlag = !this.outletOpenFlag;
          this.productOpenFlag = !this.productOpenFlag;
        });
        break;
      default:
        console.log("not known flag");
        break;
    }
  };
  @action ResetOrderObject = () => {
    this.order = null;
  };
  @action ResetOrder = () => {
    this.TC_checked = false;
    this.orderTableList = [];
    this.order = null;
    this.outletOpenFlag = !this.outletOpenFlag;
    this.productOpenFlag = !this.productOpenFlag;
  };
  @action handleRepeatOrder = async (
    orderId: string,
    repeatOrderFlag: boolean
  ) => {
    try {
      const orderRepeat = await agent.Order.findOrder(orderId);

      runInAction(() => {
        this.isRepeatOrder = repeatOrderFlag;
        this.orderToRepeat = orderRepeat;
        this.orderTableList = []; // empty the table with products
        this.order = null;
        this.specialInstructions = "";
        const localOrder = <IOrder>{};
        this.specialInstructions = this.orderToRepeat.specialInstruction;
        if (this.orderToRepeat.ordertype === "DP") 
          this.boundOrderFlag = false;
        else 
          this.boundOrderFlag = true;
        localOrder.orderId = orderRepeat.orderId;
        localOrder.B_tenant_code =
          this.rootStore.userStore.user?.company.key || "";
        localOrder.E_spec_inst = this.orderToRepeat.specialInstruction;
        localOrder.L_tenant_outlet = this.orderToRepeat.outlet.outletCode;
        localOrder.M_outlet_name = this.orderToRepeat.outlet.name;
        localOrder.N_delivery_address_1 = this.orderToRepeat.outlet.address.addressLine1;
        localOrder.O_delivery_address_2 = this.orderToRepeat.outlet.address.addressLine2;
        localOrder.Q_delivery_address_3 = this.orderToRepeat.outlet.address.addressLine3;
        localOrder.S_delivery_town = this.orderToRepeat.outlet.address.addressLine4;
        localOrder.T_delivery_address_5 = this.orderToRepeat.outlet.address.addressLine5;
        this.orderToRepeat.products.forEach((e) => {
          let product = <IProductSearchResult>{};
          product.numberOfCases = e.caseUnits;
          product.product_Name = e.name;
          product.singleUnits = e.singleUnits;
          product.product_Code = e.productCode;
          product.warehouse_Code =
            this.rootStore.userStore.user?.company.warehousecode.toString() ||
            "";
          product.ipu = e.ipu;
          product.available_Cases = e.availableUnits;
          this.orderTableList.push(product);
        });
        this.orderType = orderRepeat.ordertype;
        this.order = localOrder;
        this.outletOpenFlag = true;
        this.productOpenFlag = false;
      });
    } catch (error) {
      console.log("[ERROR] handleRepeatOrder: ", error);
    }
  };
  @action HandlePlacingSavedOrder = async (
    orderId: string,
    placeOrderFlag: boolean
  ) => {
    try {
      const orderRepeat = await agent.Order.findOrder(orderId);

      runInAction(() => {
        this.orderToRepeat = orderRepeat;
        this.isPlacingOrder = placeOrderFlag;
        this.productsList = [];
        this.orderTableList = []; // empty the table with products
        this.order = null;
        this.specialInstructions = "";

        const localOrder = <IOrder>{};

        this.specialInstructions = this.orderToRepeat.specialInstruction;
        localOrder.orderId = orderRepeat.orderId;

        localOrder.B_tenant_code =
          this.rootStore.userStore.user?.company.key || "";

        this.orderType = this.orderToRepeat.ordertype;

        if (this.orderToRepeat.ordertype === "DP") 
            this.boundOrderFlag = false;
        else this.boundOrderFlag = true;

        localOrder.J_cust_ref = this.orderToRepeat.customerReference;
        localOrder.U_tenant_cust_ref = this.orderToRepeat.deliveryPointReference;
        localOrder.E_spec_inst = this.orderToRepeat.specialInstruction;
        localOrder.L_tenant_outlet = this.orderToRepeat.outlet.outletCode;
        localOrder.M_outlet_name = this.orderToRepeat.outlet.name;
        localOrder.N_delivery_address_1 = this.orderToRepeat.outlet.address.addressLine1;
        localOrder.O_delivery_address_2 = this.orderToRepeat.outlet.address.addressLine2;
        localOrder.Q_delivery_address_3 = this.orderToRepeat.outlet.address.addressLine3;
        localOrder.S_delivery_town = this.orderToRepeat.outlet.address.addressLine4;
        localOrder.T_delivery_address_5 = this.orderToRepeat.outlet.address.addressLine5;

        this.orderToRepeat.products.forEach((e) => {
          let product = <IProductSearchResult>{};
          product.numberOfCases = e.caseUnits;
          product.product_Name = e.name;
          product.singleUnits = e.singleUnits;
          product.product_Code = e.productCode;
          product.warehouse_Code =
            this.rootStore.userStore.user?.company.warehousecode.toString() ||
            "";
          product.ipu = e.ipu;
          product.available_Cases = e.availableUnits;

          this.orderTableList.push(product);
        });

        this.order = localOrder;
      });
    } catch (error) {
      console.log("[ERROR] handleRepeatOrder: ", error);
    }
  };
  @action HandleOutletsSearch = async (query: string) => {
    try {
      if (query) {
        var user: IUser = this.rootStore.userStore.user!;
        const result = await agent.Order.searchOutlets(
          query.replace(/\s\s+/g, " "),
          this.orderType,
          user.company.warehousecode,
          this.defaultDeliveryMethod
        );
        if (result.outletOption.length > 0)
          runInAction(() => {
            this.outletsList = result.outletOption;
            this.outletListSearchResult = result.outletSearchResult;
          });
      }
    } catch (error) {
      console.log("[OUTLET SEARCH ERROR]: ", error);
    }
  };
  @action DeliveryPointRefChange = async (delRef: string) => {
    try {
      if (this.order) this.order.U_tenant_cust_ref = delRef;
    } catch (error) {
      console.log("[ERROR] Delivery Reference Change: ", error);
    }
  };
  @action MyReferenceChange = async (myRef: string) => {
    try {
      if (this.order) this.order.J_cust_ref = myRef;
    } catch (error) {
      console.log("my reference Change [ERROR]: ", error);
    }
  };
  // handle se selected outlet
  @action AddSelectedOutlet = async (data: string) => {
    try {
      runInAction(() => {
        let index = this.outletsList.findIndex((o) => o.key === data);
        this.initialState = this.outletListSearchResult[index];

        this.loadFormOrder_helper(this.initialState, []);
      });
    } catch (error) {
      console.log("[Add Selected Outlet - ERROR]: ", error);
    }
  };
  @action setOrderType = async (oType: string) => {
    this.orderType = oType;
    if (oType === "UB") {
      this.boundOrderFlag = true;
      for (let i = 0; i < this.orderTableList.length; i++) {
        this.orderTableList[i].singleUnits = 0;
      }
    } else this.boundOrderFlag = false;
  };
  @action HandleProductsSearch = async (query: string) => {
    try {
      if (query) {
        var user: IUser = this.rootStore.userStore.user!;
        const result = await agent.Order.searchProducts(
          user.company.warehousecode,
          query.replace(/\s\s+/g, " ")
        );
        if (result.productOption.length > 0)
          runInAction(() => {
            this.productsList = result.productOption;
            this.productsListSearchResult = result.productSearchResult;
          });
        else {
          runInAction(() => {
            this.productsList = [];
          });
        }
      }
    } catch (error) {
      console.log("[PRODUCT SEARCH ERROR]: ", error);
    }
  };
  @action SaveOrderDetails = async () => {
    try {
      runInAction(() => {
        this.submittingData = true;
      });

      if (this.order) {
        this.order.status = false;
        this.order.C_ord_type_code = this.orderType;

        let cookie = new Cookies();
        console.log("this.orderType: ", this.orderType);
        cookie.setCookies(this.orderType, 30, "orderType");
        cookie.setCookies(this.defaultDeliveryMethod, 30, "delMethod");
        if (this.rootStore.userStore.user)
          this.order.OrderSender = this.rootStore.userStore.user;

        let finalList: IProductFinalList[] = [];

        for (let i = 0; i < this.orderTableList.length; i++) {
          let listItem = <IProductFinalList>{};
          listItem.numberOfCases = this.orderTableList[i].numberOfCases;
          listItem.product_Code = this.orderTableList[i].product_Code;
          listItem.product_Name = this.orderTableList[i].product_Name;
          listItem.singleUnits = this.orderTableList[i].singleUnits;
          listItem.warehouse_Code = this.orderTableList[i].warehouse_Code;
          finalList.push(listItem);
        }

        this.order.ProductList = finalList;
        this.order.delMethod = this.defaultDeliveryMethod
        const order = await agent.Order.save(this.order);
        if (order) {
          history.push(`/customer-profile`);
          toast.info("This Order was successfully Saved.");
          runInAction(() => {
            this.orderType = "DP";
            this.defaultDeliveryMethod = "DEFAULT";
            this.productOpenFlag = false;
            this.outletOpenFlag = true;
            this.order = null;
            this.TC_checked = false;
            this.submittingData = false;
            this.orderTableList = [];
            this.specialInstructions = "";
            this.singleUnitsError = false;
            this.caseUnitsError = false;
          });
        }
      }
    } catch (error) {
      console.log("[saving DATA ERROR]: ", error);
      throw error;
    } finally {
      runInAction(() => {
        this.submittingData = false;
      });
    }
  };
  @action SubmitOrderDetails = async () => {
    try {
      this.submittingData = true;

      if (this.order) {
        // this.order.orderId = values.orderId;
        this.order.status = true;
        this.order.delMethod = this.defaultDeliveryMethod;
        this.order.C_ord_type_code = this.orderType;
        console.log("this.orderType: ", this.orderType);
        if (this.rootStore.userStore.user)
          this.order.OrderSender = this.rootStore.userStore.user;

        let finalList: IProductFinalList[] = [];

        for (let i = 0; i < this.orderTableList.length; i++) {
          let listItem = <IProductFinalList>{};

          listItem.numberOfCases = this.orderTableList[i].numberOfCases;
          listItem.product_Code = this.orderTableList[i].product_Code;
          listItem.product_Name = this.orderTableList[i].product_Name;
          listItem.singleUnits = this.orderTableList[i].singleUnits;
          listItem.warehouse_Code = this.orderTableList[i].warehouse_Code;
          finalList.push(listItem);
        }
        this.order.ProductList = finalList;
        const order = await agent.Order.create(this.order);

        if (order) {
          let cookie = new Cookies();
          console.log("this.orderType: ", this.orderType);
          cookie.setCookies(this.orderType, 30, "orderType");
          cookie.setCookies(this.defaultDeliveryMethod, 30, "delMethod");
          history.push(`/customer-profile`);
          toast.success("This Order was successfully sent.");
          runInAction(() => {
            this.orderType = "DP";
            this.defaultDeliveryMethod = "DEFAULT";
            this.productOpenFlag = false;
            this.outletOpenFlag = true;
            this.order = null;
            this.TC_checked = false;
            this.orderTableList = [];
            this.specialInstructions = "";
            this.submittingData = false;
            this.singleUnitsError = false;
            this.caseUnitsError = false;
          });
        }
      }
    } catch (error) {
      console.log("[SUBMITTING DATA ERROR]: ", error);
      throw error;
    } finally {
      runInAction(() => {
        this.submittingData = false;
      });
    }
  };
  @action HandleRemoveProduct = async (product: IProductSearchResult) => {
    runInAction(() => {
      if (this.orderTableList.includes(product)) {
        var index = this.orderTableList.indexOf(product);
        this.orderTableList.splice(index, 1);
      }
      if (this.orderTableList.length === 0) {
        this.singleUnitsError = false;
        this.caseUnitsError = false;
      }
    });
  };
  @action AddProductToTable = async (value: string) => {
    let index = this.productsList.findIndex(
      (o) => o.value === value.toString()
    );

    this.orderTableList.push(this.productsListSearchResult[index]);

    this.productsList = [];
  };
  @action UpdateSingleUnits = async (quantity: number, index: number) => {
    runInAction(() => {
      this.orderTableList[index].singleUnits < 0
        ? (this.orderTableList[index].singleUnits = 0)
        : (this.orderTableList[index].singleUnits = Number(quantity));

      let sUnitsVal = this.orderTableList[index].singleUnits;
      let casesVal = this.orderTableList[index].numberOfCases;
      let available_CasesVal = this.orderTableList[index].available_Cases;
      let ipu = this.orderTableList[index].ipu;
      let qty = Number(quantity);

      if (casesVal * ipu + qty > ipu * available_CasesVal) {
        this.singleUnitsError = true;
        this.orderTableList[index].singlesErrorFlag = true;
      } else if (sUnitsVal === 0 && casesVal <= available_CasesVal) {
        this.orderTableList[index].casesErrorFlag = false;
        this.caseUnitsError = false;
        this.singleUnitsError = false;
        this.orderTableList[index].singlesErrorFlag = false;
      } else {
        this.orderTableList[index].singlesErrorFlag = false;
        this.singleUnitsError = false;
      }
    });
  };
  @action UpdateNumCases = async (quantity: number, index: number) => {
    runInAction(() => {
      this.orderTableList[index].numberOfCases < 0
        ? (this.orderTableList[index].numberOfCases = 0)
        : (this.orderTableList[index].numberOfCases = Number(quantity));

      if (
        this.orderTableList[index].numberOfCases +
          this.orderTableList[index].singleUnits >
        this.orderTableList[index].available_Cases
      ) {
        this.orderTableList[index].casesErrorFlag = true;
        this.caseUnitsError = true;
      } else {
        this.orderTableList[index].casesErrorFlag = false;
        this.caseUnitsError = false;
      }
    });
  };
  loadFormOrder_helper(outlet: IOutletSearchResult, products: Array<{}>) {
    let localOrder = <IOrder>{};

    localOrder.B_tenant_code = this.rootStore.userStore.user?.company.key || "";
    localOrder.C_ord_type_code = this.orderType;
    localOrder.E_spec_inst = "";
    localOrder.F_spec_inst2 = "";
    localOrder.I_spec_inst3 = "";
    localOrder.J_cust_ref = "";
    localOrder.L_tenant_outlet = outlet.edI_CODE1;
    localOrder.M_outlet_name = outlet.name;
    localOrder.N_delivery_address_1 = outlet.addresS_LINE_1;
    localOrder.O_delivery_address_2 = outlet.addresS_LINE_2;
    localOrder.Q_delivery_address_3 = outlet.addresS_LINE_3;
    localOrder.S_delivery_town = outlet.town;
    localOrder.T_delivery_address_5 = outlet.postcode;
    localOrder.U_tenant_cust_ref = "";
    localOrder.V_spec_inst4 = "";
    localOrder.ProductList = products;

    this.order = localOrder;
  }
  // handle special instructions change
  @action handleSpecialInstructionsChange = async (
    specialInstruction: string
  ) => {
    try {
      runInAction(() => {
        this.specialInstructions = specialInstruction;

        if (this.order) {
          let len = specialInstruction.match(/.{1,200}/g)?.length;
          let val = specialInstruction.match(/.{1,200}/g) || "";
          switch (len) {
            case 1:
              this.order.E_spec_inst = val[0];
              break;
            case 2:
              this.order.E_spec_inst = val[0];
              this.order.F_spec_inst2 = val[1];
              break;
            case 3:
              this.order.E_spec_inst = val[0];
              this.order.F_spec_inst2 = val[1];
              this.order.I_spec_inst3 = val[2];
              break;
            case 4:
              this.order.E_spec_inst = val[0];
              this.order.F_spec_inst2 = val[1];
              this.order.I_spec_inst3 = val[2];
              this.order.V_spec_inst4 = val[3];
              break;
            default:
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  @action resetOutletsList = async () => {
    this.outletsList = [];
  };
}
