import React from "react";
import {
  Form,
  Label,
  FormFieldProps,
  Select
} from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const SelectInput: React.FC<IProps> = ({
  input,
  width,
  placeholder,
  required,
  options,
  key,
  searchChange,
  label,
  meta: { touched, error }
}) => {
  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <Select onSearchChange={() => searchChange}
        key={options}
        search
        required={required}
        placeholder={placeholder}
        value={input.value}
        onChange={(e, data) => input.onChange(data.value)}
        options={options}
      />

      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};
export default SelectInput;
