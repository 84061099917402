import React, { useContext } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";

const DeleteConfirmationModal = () => {
  const rooStore = useContext(RootStoreContext);
  const { closeDelModal, delModalOpen, removeConfirmation } = rooStore.profileStore;

  return (
    <Modal open={delModalOpen} onClose={closeDelModal} closeIcon   style={{
        height: 200,
        width: 550,
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: "auto"
      }}>
    <Modal.Header>
        Delete Order Confirmation
    </Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete this order?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={closeDelModal}>
          <Icon name="remove" /> No
        </Button>
        <Button color="green" onClick={() => removeConfirmation()}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};


export default observer(DeleteConfirmationModal)