export class Cookies {
  // save
  setCookies(value: string, days: number, name: string): void {
    var expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name+ "=" + (value || "") + expires + "; path=/";
  }
  //retrieve
  getCookie(name: string): string {
    let cookie: string = "";
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) cookie = c.substring(nameEQ.length, c.length);
    }
    return cookie;
  }
  //check
  checkCookie(name: string): boolean {
    if (this.getCookie(name) !== "") return true;
    return false;
  }
}
