import React from "react";
import { Form, Label} from "semantic-ui-react";
import { FieldRenderProps } from "react-final-form";
 

interface IProps extends FieldRenderProps<string, HTMLElement> {}

const TextInput: React.FC<IProps> = ({input,label, value,  width, defaultValue, readOnly,  type, placeholder, maxLength, required, meta: {touched, error}}) => {
  return (
    <Form.Field error={touched && !!error} type={type} width={width}>
      <label>{label}</label>
      <input {...input} placeholder={placeholder} defaultValue={defaultValue}  value={value} required={required} maxLength={maxLength} readOnly={readOnly} />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};
export default TextInput
