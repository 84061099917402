import React, { Fragment, useState, useEffect } from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import PhotoWidgetDropZone from "./photoWidgetDropzone";


const UploadPhoto = () => {

  const headerStyle = {
    textAlign: "center",
    paddingBottom: 10,
    textWeight: "strong"
  }


  const [files, setFiles] = useState<any[]>([])
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  });

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={7}>
          <Header
            style={headerStyle}
            color="teal"
            sub
            content="Step 1 - Add Photo"
          />
          <PhotoWidgetDropZone setFiles={setFiles} />
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column width={7}>
          <Header
            style={headerStyle}
            color="teal"
            sub
            content="Step 2 - Preview"
          />
          {files.length > 0 && <Image src={files[0].preview} />}
        </Grid.Column>
      </Grid>
    </Fragment>
  );
}

export default UploadPhoto;
